import * as React from 'react';
import { Helmet } from 'react-helmet';
import Benefits from '../compontents/Benefits/Benefits';
import '../compontents/Benefits/Benefits.module.scss';
import Capabilities from '../compontents/Capabilities/Capabilities';
import ContactUs from '../compontents/ContactUs/ContactUs';
import Layout from '../compontents/Layout/Layout';
import MeetUs from '../compontents/MeetUs/MeetUs';
import { img_container_col, intro_header, intro_image, intro_image_container } from './solutions.module.scss';

type SolutionsPageProps = {
    location: any;
};

// markup
const SolutionsPage: React.FC<SolutionsPageProps> = ({ location }) => {
    return (
        <Layout location={location}>
            <Helmet>
                <title>Solutions - Cloud, Automation, and Hosting!</title>
            </Helmet>
            <div className="intro-section row" data-testid="intro-section">
                <div className={`${img_container_col} col-md-6 col-lg-4 col-xl-3 mb-2 mb-md-0`}>
                    <div className={intro_image_container}>
                        <img
                            className={intro_image}
                            src="../images/solutions-header-medium.jpg"
                            alt="Woman at computer"
                        />
                    </div>
                </div>
                <div className="col-md-5 offset-md-1">
                    <h1 className={intro_header} data-testid="intro-section-title">
                        Having terrible response times for your managed services?
                    </h1>
                    <p data-testid="intro-section-body">Try our improved managed services model</p>
                    <a href="#Contact Us" data-testid="intro-section-link">
                        Get In Touch
                    </a>
                </div>
            </div>
            <div id="solutions-list-click" className="d-flex justify-content-center d-none d-lg-block">
                <div className="row d-none d-lg-block">
                    <ul
                        className="list-group list-group-horizontal d-flex justify-content-center"
                        data-testid="solutions-list">
                        <li
                            className="d-flex justify-content-center d-none d-lg-block m-1 p-2"
                            data-testid="benefits-button">
                            <a
                                className="list-group-item list-group-item-action m-1 d-none d-lg-block"
                                href="#Benefits">
                                <h5>Concerns</h5>
                            </a>
                        </li>
                        <li
                            className="d-flex justify-content-center d-none d-lg-block m-1 p-2"
                            data-testid="benefits-button">
                            <a className="list-group-item list-group-item-action m-1 d-none d-lg-block" href="#MeetUs">
                                <h5>Discussion</h5>
                            </a>
                        </li>
                        <li
                            className="d-flex justify-content-center d-none d-lg-block m-1 p-2"
                            data-testid="features-button">
                            <a
                                className="list-group-item list-group-item-action m-1 d-none d-lg-block"
                                href="#Capabilities">
                                <h5>Solutions</h5>
                            </a>
                        </li>
                        <li
                            className="d-flex justify-content-center d-none d-lg-block m-1 p-2"
                            data-testid="contact-us-button">
                            <a
                                className="list-group-item list-group-item-action m-1 d-none d-lg-block"
                                href="#Contact Us">
                                <h5>Contact Us</h5>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="solutions-list-click" className="d-flex justify-content-center d-sm-block d-lg-none">
                <div className="row d-sm-block d-lg-none">
                    <ul className="list-group d-flex justify-content-center">
                        <li className="d-flex justify-content-center d-sm-block d-lg-none m-1 p-2">
                            <a
                                className="list-group-item list-group-item-action m-1 d-sm-block d-lg-none"
                                href="#Benefits">
                                <h5>Concerns</h5>
                            </a>
                        </li>
                        <li
                            className="d-flex justify-content-center d-sm-block d-lg-none m-1 p-2"
                            data-testid="benefits-button">
                            <a
                                className="list-group-item list-group-item-action m-1 d-sm-block d-lg-none"
                                href="#MeetUs">
                                <h5>Discussion</h5>
                            </a>
                        </li>
                        <li className="d-flex justify-content-center d-sm-block d-lg-none m-1 p-2">
                            <a
                                className="list-group-item list-group-item-action m-1 d-sm-block d-lg-none"
                                href="#Capabilities">
                                <h5>Solutions</h5>
                            </a>
                        </li>
                        <li className="d-flex justify-content-center d-sm-block d-lg-none m-1 p-2">
                            <a
                                className="list-group-item list-group-item-action m-1 d-sm-block d-lg-none"
                                href="#Contact Us">
                                <h5>Contact Us</h5>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                data-bs-spy="scroll"
                data-bs-scroll="#solution-list-click"
                data-bs-offset="0"
                className="position-relative">
                <div id="Benefits">
                    <Benefits></Benefits>
                </div>
                <div id="MeetUs">
                    <MeetUs></MeetUs>
                </div>
                <div id="Capabilities">
                    <Capabilities></Capabilities>
                </div>
                <div id="Contact Us">
                    <ContactUs></ContactUs>
                </div>
            </div>
        </Layout>
    );
};

export default SolutionsPage;
