import React from 'react';
import Logo from '../../images/Square-Hook-Logo.inline.svg';
import {
    capabilites_images,
    capabilites_images_2,
    caret_icon,
    more_here,
    squarehook_logo,
} from './Capabilities.module.scss';

const Capabilities: React.FC = () => {
    return (
        <div className={'capabilities-container container-fluid p-5 m-2'} data-testid="capabilities-container">
            <div>
                <div className="d-flex justify-content-center p-2">
                    <h2>Solutions:</h2>
                </div>
                <div className="row">
                    <div className="col-lg-5 col-md-7 p-2">
                        <div className="list-group" id="list-tab" role="tablist" data-testid="tabbed-list">
                            <a
                                className="list-group-item list-group-item-action active"
                                id="list-home-list"
                                data-bs-toggle="list"
                                href="#list-home"
                                role="tab"
                                aria-controls="list-home"
                                data-testid="tabbed-list-1">
                                Devops and Cloud
                            </a>
                            <a
                                className="list-group-item list-group-item-action"
                                id="list-profile-list"
                                data-bs-toggle="list"
                                href="#list-profile"
                                role="tab"
                                aria-controls="list-profile"
                                data-testid="tabbed-list-2">
                                Custom Development
                            </a>
                            <a
                                className="list-group-item list-group-item-action"
                                id="list-messages-list"
                                data-bs-toggle="list"
                                href="#list-messages"
                                role="tab"
                                aria-controls="list-messages">
                                Managed IT Services
                            </a>
                            <a
                                className="list-group-item list-group-item-action"
                                id="list-settings-list"
                                data-bs-toggle="list"
                                href="#list-settings"
                                role="tab"
                                aria-controls="list-settings">
                                Learn More
                            </a>
                            <Logo className={`${squarehook_logo} m-2 d-none d-md-block`} />
                        </div>
                    </div>
                    <div className="col-lg-7 col-sm-5">
                        <div className="tab-content" id="nav-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="list-home"
                                role="tabpanel"
                                aria-labelledby="list-home-list"
                                data-testid="tabbed-pane-1">
                                <div className="pb-4">
                                    <h2>DevOps and Cloud</h2>
                                    <ul>
                                        <li>Creating CI/CD pipeline</li>
                                        <li>Cost audit</li>
                                        <li>Security audit </li>
                                        <li>Infrastructure as code with terraform & ARM templates</li>
                                        <li>creating cloud infrastructure</li>
                                        <li>creating multiple servers in multiple regions</li>
                                    </ul>
                                    <div className="d-flex">
                                        <a href="/devops" className={more_here}>
                                            More Info <i className={`${caret_icon} fa-solid fa-angle-right`}></i>
                                        </a>
                                    </div>
                                </div>
                                <img
                                    src="../images/cloud-image-old.jpg"
                                    alt="Products Online"
                                    className={`${capabilites_images} img-fluid`}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="list-profile"
                                role="tabpanel"
                                aria-labelledby="list-profile-list"
                                data-testid="tabbed-pane-2">
                                <h2>Custom Development</h2>
                                <ul>
                                    <li>Automated testing for all environments</li>
                                    <li>On demand work </li>
                                    <li>Clean and easy User Experience</li>
                                    <li>Secure and fast data</li>
                                </ul>
                                <div className="d-flex">
                                    <a href="/custom-software" className={more_here}>
                                        More Info<i className={`${caret_icon} fa-solid fa-angle-right`}></i>
                                    </a>
                                </div>
                                <img
                                    src="../images/automation-image.jpg"
                                    alt="Option 2"
                                    className={`${capabilites_images_2} img-fluid`}
                                />
                            </div>

                            <div
                                className="tab-pane fade"
                                id="list-messages"
                                role="tabpanel"
                                aria-labelledby="list-messages-list">
                                <div className="pb-4">
                                    <h2>Managed IT Services</h2>
                                    <ul>
                                        <li>Implementing our improved managed services model </li>

                                        <li>Improvement model </li>

                                        <li>Reduce your cost of hosting </li>

                                        <li>Multi-region hosting </li>

                                        <li>Uptime reporting </li>

                                        <li>Quick response time if site is down </li>
                                    </ul>
                                    <div className="d-flex">
                                        <a href="/managed-it" className={more_here}>
                                            More Info <i className={`${caret_icon} fa-solid fa-angle-right`}></i>
                                        </a>
                                    </div>
                                </div>
                                <img
                                    src="../images/testing-image.jpg"
                                    alt="Capabilities Image"
                                    className={`${capabilites_images} img-fluid`}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="list-settings"
                                role="tabpanel"
                                aria-labelledby="list-settings-list">
                                <h2>Learn More</h2>
                                <p>Learn More about us and get into contact.</p>
                                <div className="d-flex mt-5">
                                    <a href="/about-us" className={more_here}>
                                        More Info <i className={`${caret_icon} fa-solid fa-angle-right`}></i>
                                    </a>
                                </div>
                                <img
                                    src="../images/developers-image.jpg"
                                    alt="Group Of Developers"
                                    className={`${capabilites_images_2} img-fluid`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Capabilities;
