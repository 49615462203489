import React from 'react';
import { Card, CardGroup } from 'react-bootstrap';
import { card_body, features_section, feature_card1, feature_card2, feature_card3 } from './MeetUs.module.scss';

const MeetUs: React.FC = () => {
    return (
        <div className={features_section} data-testid="meet-us-section">
            <div className="d-flex justify-content-center">
                <h1>Discussion</h1>
            </div>
            <div className="features-container" data-testid="meet-us-container">
                <CardGroup>
                    <Card data-testid="meet-us-card-1" className={`${feature_card1} p-2 mx-3`}>
                        <Card.Img variant="top" src="../../images/call.jpg" />
                        <Card.Body className={card_body}>
                            <Card.Title>Contact Us</Card.Title>
                            <Card.Text>
                                Give us a brief description of yourself and the company and what concerns you have so we
                                may further assist.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card data-testid="meet-us-card-2" className={`${feature_card2} p-2 mx-3`}>
                        <Card.Img variant="top" src="../../images/compile-solutions.jpg" />
                        <Card.Body className={card_body}>
                            <Card.Title>Compile Solutions</Card.Title>
                            <Card.Text>
                                We will create a list of solutions to resolve your concerns in a effective manner.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card data-testid="meet-us-card-3" className={`${feature_card3} p-2 mx-3`}>
                        <Card.Img variant="top" src="../../images/meeting.jpg" />
                        <Card.Body className={card_body}>
                            <Card.Title>Meet Up</Card.Title>
                            <Card.Text>
                                Schedule a time to meet. This will give us an opportunity to get to know you and help
                                curate the best solutions for you.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </div>
        </div>
    );
};

export default MeetUs;
