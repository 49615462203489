import React from 'react';
import { ThemeProvider } from 'react-bootstrap';
import { benefits_container, benefits_image } from './Benefits.module.scss';

const Benefits: React.FC = () => {
    return (
        <ThemeProvider breakpoints={['xl', 'lg', 'md', 'sm', 'xs']}>
            <div className={benefits_container} data-testid="benefits-container">
                <div className="row">
                    <div className="col-lg-8 col-md-4">
                        <img
                            src="../../images/frustration.jpg"
                            data-testid="benefits-image"
                            className={`${benefits_image} img-fluid`}
                            alt="Frustrated User Image"
                        />
                    </div>
                    <div className=" col-lg-4 col-md-4 p-4" data-testid="benefits-list">
                        <h2>What are your concerns?</h2>
                        <ul>
                            <li>Terrible response times from other providers.</li>
                            <li>Needing automated testing for all environments.</li>
                            <li>Wanting new applications to improve business.</li>
                            <li>Slow deployment.</li>
                            <li>Accruing too much technical debt.</li>
                            <li>
                                Only having one server location resulting in constricted bandwidth and longer response
                                time.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default Benefits;
